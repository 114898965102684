<template>
    <!-- 在线聊天 -->
    <v-container fluid>
        <v-card class="mt-4" style="max-height:80vh">
            <!-- <iframe id="chat-iframe" src="https://chat.navi-tech.net/direct/AGvY8vshtPFaNnkD9/?layout=embedded"></iframe> -->
            <v-row class="ma-0">
                <!-- 侧边栏 -->
                <v-col cols="3" class="overflow-y-auto" style="background-color: #2f343d;">
                    <!-- 用户头像 -->
                    <v-card-title>
                        <v-badge bottom offset-x="15" offset-y="15" :icon="userStatusIcon.iconName" :color="userStatusIcon.color">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar v-bind="attrs" v-on="on" size="45" tile :color="userStatusIcon.avatarColor">
                                <span class="white--text text-h5">{{username.charAt(0).toUpperCase()}}</span>
                              </v-avatar>
                            </template>
                            <span>{{userStatusIcon.status}}</span>
                          </v-tooltip>
                        </v-badge>
                    </v-card-title>
                    <!-- 通道 -->
                    <v-card-subtitle class="white--text mt-2">Channels</v-card-subtitle>
                    <v-card-text @click="getChatTitleByChannels('primary',roomAvatar,'mdi-pound',`${roomPrefix}${username}`)">
                        <div class="mouse-over">
                          <v-avatar size="30" tile color="primary">
                            <span class="white--text text-h5">{{roomPrefix.charAt(0).toUpperCase()}}</span>
                          </v-avatar>
                          <span class="white--text"><v-icon class="white--text px-1" size="16">mdi-pound</v-icon>{{`${roomPrefix}${username}`}}</span>
                        </div>
                    </v-card-text>
                     <!-- 直接消息 -->
                    <v-card-subtitle class="white--text" v-if="memberList.length > 0">User List</v-card-subtitle>
                    <v-card-text style="max-height:55vh" class="overflow-y-auto">
                        <div v-for="(member,i) in memberList" :key="i" class="mb-2">
                        <v-avatar size="30" tile :color="member.avatarColor">
                            <span class="white--text text-h5">{{member.username.charAt(0).toUpperCase()}}</span>
                        </v-avatar>
                        <span class="white--text">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="white--text px-1" size="16" :color="memberStatusIcons.filter(icon => icon.status == member.status)[0].color">
                                  {{memberStatusIcons.filter(icon => icon.status == member.status)[0].iconName}}
                                </v-icon>
                              </template>
                              <span>{{member.status}}</span>
                            </v-tooltip>
                            {{member.username}}
                        </span>
                        </div>
                    </v-card-text>
                </v-col>
                <!-- 聊天对话框 -->
                <v-col cols="9">
                    <!-- 标题 -->
                    <v-card-title>
                        <v-avatar size="30" tile :color="chatTitleAvatarColor">
                            <span class="text-h5">{{roomPrefix.charAt(0).toUpperCase()}}</span>
                        </v-avatar>
                        <span class=""><v-icon class="px-1" size="16" :color="chatTitleIconColor">{{chatTitleIcon}}</v-icon>{{`${roomPrefix}${username}`}}</span>
                        <v-spacer></v-spacer>
                        <!-- <v-icon>mdi-account-supervisor-outline</v-icon> -->
                    </v-card-title>
                    <v-divider></v-divider>
                    <!-- 对话框消息体 -->
                    <v-card-text style="max-height:65vh;min-height:25vh" class="overflow-y-auto" id="messageBox">     
                      <div class="mb-2 mt-4 text-center">Start of conversation</div>            
                      <div v-for="(value, key, index) in messageList" :key="index">
                        <!-- 时间 -->
                        <div class="rcx-message-divider mt-2">
                          <div class="rcx-message-divider__bar"></div>
                          <div class="text-subtitle-2 text-center">{{key}}</div>
                          <div class="rcx-message-divider__bar"></div>
                        </div>
                        <!-- 消息体 -->
                        <div v-for="(message,i) in value" :key="i"> 
                          <!-- 1min内的消息合并在一起 -->
                          <v-row dense v-if="i > 0 && new Date(value[i]._updatedAt).getTime()-new Date(value[i-1]._updatedAt).getTime() <= 60000 && value[i].u.username==value[i-1].u.username" 
                            class="mt-n1 mb-n5">
                            <v-col cols="auto">
                              <v-avatar size="40"></v-avatar>
                            </v-col>
                            <v-col cols="11" class="">
                              {{message.msg}}
                            </v-col>
                          </v-row>
                          <v-row dense v-else class="mt-1">
                            <!-- 头像 -->
                            <v-col cols="auto">
                              <v-avatar size="40" tile :color="memberList.filter(member => member.username == message.u.username)[0].avatarColor">
                                <span class="white--text text-h5">{{message.u.username.charAt(0).toUpperCase()}}</span>
                              </v-avatar>
                            </v-col>
                            <v-col cols="11">
                              <v-row dense no-gutters>
                                <!-- 用户信息 -->
                                <v-col cols="12">
                                  <span class="font-weight-bold mr-1">{{message.u.username}}</span>
                                  <span class="grey--text text-caption">{{message.timeFormat}}</span>
                                </v-col>
                                <!-- 消息 -->
                                <v-col cols="12">
                                  {{message.msg}}
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-card-text>
                    <!-- 消息发送框 -->
                    <div class="mt-3">
                      <v-text-field v-model="messageText" class="" placeholder="Message" outlined hide-details="auto" @keyup.enter="sendMessage()"></v-text-field>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl;
let rocketchatApi = commonCfg.rocketchatUrl
let headersConfig = null;
let vm;
export default {
    data() {
        return {
          roomPrefix: 'gnss_',
          username: localStorage.getItem("username"),
          userAvatar: '',
          //roomName: '',
          roomAvatar: '',
          chatTitleAvatarColor: 'primary',//默认值
          chatTitleAvatarName: '',//默认值
          chatTitleIcon: 'mdi-pound',//默认值
          chatTitleIconColor: 'black',//默认值
          chatTitleObjectName: '',//默认值
          memberAvatarColors: ['teal', 'indigo', 'purple', 'deep-purple', 'brown', 'lime', 'orange'],
          memberStatusIcons: [
              {status: 'offline', iconName: 'mdi-checkbox-blank-circle-outline', color: 'white'},
              {status: 'busy', iconName: 'mdi-minus-circle', color: 'red'},
              {status: 'away', iconName: 'mdi-clock-time-five', color: 'orange'},
              {status: 'online', iconName: 'mdi-checkbox-blank-circle', color: 'green'}
          ], 
          memberList: [],
          userStatusIcon: '',
          messageList: [],
          messageText: ''
        }
    },
    async created() {
      vm = this
      await this.getRocketChatToken();
      await this.getMemberList()
      this.getMessageList()
      this.getRoomInfo()
      
    },
    methods: {
        //获得聊天框标题
        getChatTitleByChannels(avatarColor,avatarName,icon,objectName ) {
            this.chatTitleAvatarColor = avatarColor
            this.chatTitleAvatarName = avatarName
            this.chatTitleIcon = icon
            this.chatTitleObjectName = objectName
            this.chatTitleIconColor = 'black'
        },
        getChatTitleByDirectMsg(member) {
            this.chatTitleAvatarColor = member.avatarColor
            this.chatTitleAvatarName = member.username.charAt(0).toUpperCase()
            this.chatTitleIcon = this.memberStatusIcons.filter(icon => icon.status == member.status)[0].iconName
            this.chatTitleObjectName = member.username
            let iconColor = this.memberStatusIcons.filter(icon => icon.status == member.status)[0].color
            this.chatTitleIconColor = iconColor == 'white' ? 'black' : iconColor
        },
        //创建用户token
        async getRocketChatToken() {
           let res = await axios.get(`${apiUrl}user/rocketchat/create-token`)
           //console.log(res)
           if(!res || !res.data.success) return null
           headersConfig = {
            headers: {
              'X-Auth-Token': res.data.data.authToken,
              'X-User-Id': res.data.data.userId,
            }
          }
          return headersConfig
        },
        //获得成员列表
        async getMemberList() {
          if(!headersConfig) return
          await axios.get(`${rocketchatApi}groups.members?roomName=${this.roomPrefix}${this.username}`, headersConfig).then(res => {
            if(!res.data.success) return
            //给每一个用户分配好头像的颜色
            for(let i = 0;i < res.data.members.length; i++) {
                res.data.members[i]['avatarColor'] = i < this.memberAvatarColors.length ? this.memberAvatarColors[i] : this.memberAvatarColors[i % this.memberAvatarColors.length]
            }
            this.memberList = res.data.members
            //当前用户的状态icon信息
            let user = this.memberList.filter(member => member.username == this.username)[0]
            this.userStatusIcon = this.memberStatusIcons.filter(icon => icon.status == user.status)[0]
            this.userStatusIcon['avatarColor'] = user.avatarColor//把头像颜色放进状态icon里
          }).catch(error => {
            console.log(error)
            this.memberList = []
          })
        },
        //获得历史消息列表
        getMessageList() {
          if(!headersConfig) return
          axios.get(`${rocketchatApi}groups.messages?roomName=${this.roomPrefix}${this.username}`, headersConfig).then(res => {
            if(!res.data.success) return
            let data = {}
            for(let i = res.data.messages.length -1; i>=0; i--) {
              //let times = message._updatedAt.split('T')
              let date = new Date(res.data.messages[i]._updatedAt)
              let localDateString = date.toLocaleDateString()//UTC转本地日期
              if(data[localDateString]) {
                  data[localDateString].push(res.data.messages[i])
              }else{
                  data[localDateString] = [res.data.messages[i]]
              }
              let localTimeString =  date.toLocaleTimeString()
              let splitStrs1 = localTimeString.split(" ")
              let splitStrs2 =  splitStrs1[0].split(":")
              res.data.messages[i]['timeFormat'] = `${splitStrs2[0]}:${splitStrs2[1]} ${splitStrs1[1]}`
            }
            console.log(data)
            this.messageList = data
            //console.log(this.messageList)

            //要等到消息加载完后再调用滚动条
            this.scrollToElement()
          }).catch(error => {
            console.log(error)
            this.messageList = []
          })
        },
        //获得房间信息
        async getRoomInfo() {
          let res =  await axios.get(`${rocketchatApi}rooms.info?roomName=${this.roomPrefix}${this.username}`, headersConfig)
          if(!res || !res.data.success) return null
          return res.data.room._id
        },
        //发送消息
        async sendMessage() {
          let roomId = await this.getRoomInfo()
          if(!roomId) return
          axios.post(`${rocketchatApi}chat.sendMessage`, {message: { rid: roomId, msg: this.messageText }},headersConfig).then(res => {
            if(!res.data.success) return
            this.getMessageList()
            this.messageText = ''
          }).catch(error => {
            console.log(error)
          }) 
        },
        //滚动条自动定位到底部
        scrollToElement() {
          this.$nextTick(() => {
            var el = vm.$el.querySelector("#messageBox");
            el.scrollTop = el.scrollHeight
          })  
        }
    }
}
</script>
<style scoped>
/* #chat-iframe {
    max-height: 80vh;
    max-width: 72.1vw;
    border: none;
} */
.mouse-over:hover {
    background-color: #6c727a;
    cursor: pointer;
}

.rcx-message-divider {
  align-items: center;
  display: flex;
}

.rcx-message-divider__bar {
  flex-grow: 1;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}

</style>